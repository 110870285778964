/* eslint-disable @typescript-eslint/no-unused-vars */
import { Navigate, useLocation } from "react-router-dom";
import { useFontDesk } from "../context/frontDeskContext";

const PublicRoute = ({ children }: any) => {
  const { pathname } = useLocation();

  const { isAuthenticated } = useFontDesk();

  if (isAuthenticated === null) return;

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  return children;
};

export default PublicRoute;
