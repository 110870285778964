import { ItemStatus } from "../utils/typeValues";
import { convertTo12HourFormat } from "../utils/utils";
import api from "./axios.config";
import {
  BookProPayload,
  BookProResponse,
  Coach,
  CoachAvailabilityAllDaysPayload,
  CoachAvailabilityResponse,
  CoachAvailabilitySingleDayPayload,
  OrderResponse,
  OtpCodeSendPayload,
  OtpCodeVerifyPayload,
  OtpCodeVerifySuccessPayload,
  SpecificBookingSlotsResponse,
  User,
  UserModel,
} from "./types";

export class FrontDeskApi {
  static async otpCodeSend(request: OtpCodeSendPayload) {
    try {
      const response = await api.post(
        "/staff/Authentication/otp/start",
        request
      );
      return response.data;
    } catch (error) {
      console.error("otpCodeSend - Error: ", error);
      throw error;
    }
  }

  static async otpCodeVerify(request: OtpCodeVerifyPayload) {
    try {
      const response = await api.post<OtpCodeVerifySuccessPayload>(
        "/staff/Authentication/otp/complete",
        request
      );
      return response.data;
    } catch (error) {
      console.error("otpCodeVerify - Error: ", error);
      throw error;
    }
  }

  static async getMe() {
    try {
      const response = await api.get<UserModel>("/staff/Me");
      return response.data;
    } catch (error) {
      console.error("otpCodeVerify - Error: ", error);
      throw error;
    }
  }

  static async getAllOrders() {
    try {
      const response = await api.get<OrderResponse[]>(`/staff/Order`);
      const formattedOrders = response.data.map((order) => ({
        ...order,
        orderLines: order.orderLines.map((orderLine) => ({
          ...orderLine,
          createdDate: orderLine.createdDate + "Z",
          staffDeliveryDate: orderLine.staffDeliveryDate
            ? orderLine.staffDeliveryDate + "Z"
            : "",
          status:
            orderLine.status.toString() === "0"
              ? ItemStatus.Pending
              : orderLine.status.toString() === "1"
              ? ItemStatus.Delivered
              : ItemStatus.Cancelled, // Set a default status value here
        })),
      }));
      return formattedOrders;
    } catch (error) {
      console.error("otpCodeVerify - Error: ", error);
      throw error;
    }
  }

  static async getOrderById(orderId: number, courtId: number = 1) {
    try {
      const response = await api.get<OrderResponse>(
        `/staff/Order/${courtId}/${orderId}`
      );
      const formattedOrders = {
        ...response.data,
        orderLines: response.data.orderLines.map((orderLine) => ({
          ...orderLine,
          createdDate: orderLine.createdDate + "Z",
          staffDeliveryDate: orderLine.staffDeliveryDate
            ? orderLine.staffDeliveryDate + "Z"
            : "",
          status:
            orderLine.status.toString() === "0"
              ? ItemStatus.Pending
              : orderLine.status.toString() === "1"
              ? ItemStatus.Delivered
              : ItemStatus.Cancelled, // Set a default status value here
        })),
      };
      return formattedOrders;
    } catch (error) {
      console.error("otpCodeVerify - Error: ", error);
      throw error;
    }
  }

  static async updateOrderStatus(orderId: number, orderLineId: number) {
    try {
      const response = await api.put(`/staff/Order/${orderId}/${orderLineId}`);
      return response.data;
    } catch (error) {
      console.error("otpCodeVerify - Error: ", error);
      throw error;
    }
  }

  static async subscribeToTopic(fcmToken: string) {
    try {
      const response = await api.post(
        `/staff/Order/subscribeTopic/${fcmToken}`
      );
      return response.data;
    } catch (error) {
      console.error("otpCodeVerify - Error: ", error);
      throw error;
    }
  }

  static async cancelOrder(orderId: number, orderLineId: number) {
    try {
      const response = await api.put(
        `/staff/Order/${orderId}/${orderLineId}/cancel`
      );
      return response.data;
    } catch (error) {
      console.error("otpCodeVerify - Error: ", error);
      throw error;
    }
  }

  static async getCoaches(
    bookingDate: string,
    startSlotTime: number,
    endSlotTime: number
  ) {
    try {
      const url = `/staff/Booking/coaches?bookingDate=${bookingDate}&startSlotTime=${startSlotTime}&endSlotTime=${endSlotTime}`;

      const response = await api.get<Coach[]>(url);
      return response.data;
    } catch (error) {
      console.error("otpCodeVerify - Error: ", error);
      throw error;
    }
  }

  static async getplayersBySearch(filterName: string, pageNum: number) {
    try {
      const url = `/staff/Booking/users?filtername=${filterName}&pageNum=${pageNum}`;

      const response = await api.get<User[]>(url);
      return response.data;
    } catch (error) {
      console.error("getUsers - Error: ", error);
      throw error;
    }
  }

  static async getSpecificBookingSlots(bookingCourtType: number, date: string) {
    try {
      const url = `/staff/Booking/specificBookingSlots?bookingCourtType=${bookingCourtType}&date=${date}`;

      const response = await api.get<SpecificBookingSlotsResponse>(url);
      console.log("api chek");
      return response.data;
    } catch (error) {
      console.error("error ", error);
      throw error;
    }
  }

  static async bookPro(request: BookProPayload) {
    try {
      const response = await api.post<BookProResponse>(
        "/staff/Booking/pro",
        request
      );
      return response.data;
    } catch (error) {
      console.error("BookPro - Error: ", error);
      throw error;
    }
  }

  static async getCoachAvailabilities(startDate: string, endDate: string) {
    try {
      const response = await api.get<CoachAvailabilityResponse[]>(
        `/staff/Availability?startDate=${startDate}&endDate=${endDate}`
      );
      return response.data.map(
        (item) =>
          ({
            ...item,
            startTime: convertTo12HourFormat(item.startTime),
          } as CoachAvailabilityResponse)
      );
    } catch (error) {
      console.error("getCoachAvailabilities - Error: ", error);
      throw error;
    }
  }

  static async postCoachAvailabilityForSingleDay(
    request: CoachAvailabilitySingleDayPayload
  ) {
    try {
      const response = await api.post("/staff/Availability", request);
      if (response.data.data) {
        return {
          ...response.data.data,
          startTime: convertTo12HourFormat(response.data.data.startTime),
        } as CoachAvailabilityResponse;
      } else {
        return null;
      }
    } catch (error) {
      console.error("postCoachAvailabilityForSingleDay - Error: ", error);
      throw error;
    }
  }

  static async postCoachAvailabilityForAllDays(
    request: CoachAvailabilityAllDaysPayload
  ) {
    try {
      const response = await api.post<{}>(
        "staff/Availability/AvailableForAll",
        request
      );
      return response.data;
    } catch (error) {
      console.error("postCoachAvailabilityForAllDays - Error: ", error);
      throw error;
    }
  }
}
