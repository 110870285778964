import React, { useEffect, useState } from "react";
import TopBar from "../../components/topBar/TopBar";
import { StaticBackdropModal } from "../../components/modal/staticBackdropModal/StaticBackdropModal";
import "./Home.css";
import { useFontDesk } from "../../context/frontDeskContext";
import { AiOutlineWarning } from "react-icons/ai";
import LoadingIndicator from "../../components/loadingIndicator/LoadingIndicator";
import { FrontDeskApi } from "../../apis/frontDesk.api";
import { Strings } from "../../utils/strings";
import Table from "../../components/table/Table";
import { OrderTableData } from "../../types/types";
import { OrderResponse } from "../../apis/types";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { getMessagingToken, onMessageListener } from "../../firebase";
import {
  formatAmount,
  requestNotificationPermission,
  sendNotification,
  updateTimer,
} from "../../utils/utils";
import { usePageVisibility } from "react-page-visibility";

export const Home = () => {
  const {
    iosInstructions,
    setIosInstructions,
    notificationPermission,
    setNotificationPermission,
    getFCMToken,
    setFCMToken,
  } = useFontDesk();

  const isVisible = usePageVisibility();
  useEffect(() => {
    if (!isVisible) return;
    getAllOrders();
  }, [isVisible]);

  const subscribeToFCMTopic = async (token: string | null) => {
    if (token && getFCMToken() !== token) {
      try {
        const response = await FrontDeskApi.subscribeToTopic(token ?? "");
        if (response.isSuccess) {
          setFCMToken(token);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    if (notificationPermission === "granted") {
      try {
        getMessagingToken().then((token) => {
          subscribeToFCMTopic(token);
        });
        onMessageListener()
          .then((payload) => {
            getAllOrders();
            sendNotification(payload.notification.title, {
              body: payload.notification.body,
            });
          })
          .catch((err) => console.log("failed: ", err));
      } catch (e) {
        console.log(e);
      }
    } else if (notificationPermission === "denied") {
      alert(Strings.NOTIFICATION_PERMISSION_DENIED);
    } else {
      setModalData({
        title: Strings.NOTIFICATION_PERMISSION,
        children: Strings.NOTIFICATION_PERMISSION_REQUIRED,
        btnText: Strings.ACCEPT,
        headerIcon: (
          <AiOutlineWarning
            style={{
              marginRight: "0.5em",
              color: "red",
              alignSelf: "center",
            }}
          />
        ),
        onClose: handleClose,
        onAccept: () =>
          requestNotificationPermission(handleClose, setNotificationPermission),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationPermission]);

  useEffect(() => {
    if (iosInstructions) {
      setModalData({
        title: Strings.ENABLE_NOTIFICATION,
        children: Strings.IOS_PERMISSION,
        btnText: "Close",
        headerIcon: (
          <AiOutlineWarning
            style={{
              marginRight: "0.5em",
              color: "red",
              alignSelf: "center",
            }}
          />
        ),
        onClose: () => {
          setIosInstructions(false);
        },
        onAccept: undefined,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iosInstructions]);

  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState<{
    title: string;
    children: React.ReactNode | string;
    btnText?: string;
    btnVariant?: string;
    onClose: () => void;
    onAccept?: () => void;
    headerIcon?: React.ReactNode;
  }>();
  const [loading, setLoading] = useState(false);
  const [orderData, setOrderData] = useState<OrderTableData[]>();
  const [orderApiResponse, setOrderApiResponse] = useState<OrderResponse[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [forceUpdate, setForceUpdate] = useState<boolean>(false);

  const forceUpdateCallback = () => {
    setForceUpdate((prev) => !prev);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      forceUpdateCallback(); // Force update to refresh timer display
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const { onLogOut, onBookPro, onCoachAvailability } = useFontDesk();
  const navigate = useNavigate();

  const handleClose = async (isLogout: boolean = false) => {
    setShow(false);
    setModalData(undefined);
  };

  const getAllOrders = async () => {
    setLoading(true);
    try {
      const response = await FrontDeskApi.getAllOrders();
      const transformedData: OrderTableData[] = response.map((data) => {
        const orderDetails = data.orderLines.reduce((fullData, line, index) => {
          fullData += `${line.productName} (${line.quantity})`;
          if (index !== data.orderLines.length - 1) {
            fullData += ", ";
          }
          return fullData;
        }, "");

        const orderTableData: OrderTableData = {
          orderId: data.name,
          orderData: orderDetails,
          orderAmount: `$${formatAmount(data.amountTotal)}`,
          courtName: data.courtName ?? "N/A",
          createdAtData: data.orderLines[0].createdDate,
        };

        return orderTableData;
      });

      setOrderData(transformedData);
      setOrderApiResponse(response);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching or transforming orders:", error);
      setLoading(false);
      setOrderData(undefined);
      setOrderApiResponse([]);
    }
  };

  const columns = [
    { header: "Court Name", accessor: "courtName" },
    { header: "Order ID", accessor: "orderId" },
    { header: "Order Data", accessor: "orderData", hiddenOnMobile: true },
    { header: "Total Amount", accessor: "orderAmount" },
    { header: "Elapsed Time", accessor: "createdAtData" },
  ];

  const onOrderItemClicked = (orderName: string) => {
    const order = orderApiResponse.find((order) => order.name === orderName);
    if (order) {
      navigate("/order-description", {
        state: { order: order },
      });
    }
  };

  return (
    <>
      <div className="outer-container">
        <TopBar
          isBack={isMobile}
          onLogout={() => {
            setModalData({
              title: Strings.LOGOUT,
              children: Strings.LOGOUT_WARNING,
              btnText: Strings.LOGOUT,
              btnVariant: "outline-danger",
              headerIcon: (
                <AiOutlineWarning
                  style={{
                    marginRight: "0.5em",
                    color: "red",
                    alignSelf: "center",
                  }}
                />
              ),
              onClose: () => {
                handleClose(true);
                onLogOut();
              },
            });
            setShow(true);
          }}
          // onBookPro={onCoachAvailability}
        />
        {!orderData && loading ? (
          <LoadingIndicator loading={loading} />
        ) : orderData?.length ? (
          isMobile ? (
            <div className="list-group mob-list-container">
              {orderApiResponse.map((data) => (
                <div
                  className="list-group-item  mob-item-container"
                  onClick={() => {
                    onOrderItemClicked(data.name);
                  }}
                >
                  <span
                    style={{
                      alignContent: "center",
                      marginBottom: 10,
                    }}
                    className="badge mob-item-qty-container rounded-pill"
                  >
                    {data.courtName}
                  </span>
                  <div className="d-flex w-100 justify-content-between">
                    <div style={{ marginBottom: "4px" }}>
                      <b className="mob-key-value">Order Id:</b>
                      <small className="mob-key-value">{data.name}</small>
                    </div>
                    <b className="mob-key-value">
                      ${formatAmount(data.amountTotal)}
                    </b>
                  </div>
                  <div className="d-flex w-100 justify-content-between">
                    <div style={{ marginBottom: "4px" }}>
                      <b className="mob-key-value">Elapsed Time:</b>
                      <small className="mob-key-value">
                        {updateTimer(new Date(data.orderLines[0]?.createdDate))}
                      </small>
                    </div>
                  </div>
                  <div style={{ marginBottom: "4px" }}>
                    <b className="mob-key-value">Items:</b>
                  </div>
                  <ol className="list-group list-group-numbered">
                    {data.orderLines.map((itemData) => (
                      <li className="list-group-item d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          {itemData.productName}
                        </div>
                        <span className="badge mob-item-qty-container rounded-pill">
                          {itemData.quantity}
                        </span>
                      </li>
                    ))}
                  </ol>
                </div>
              ))}
            </div>
          ) : (
            <div className="home-container">
              <p className="orders">{Strings.ORDERS}</p>
              <div className="innerContainer">
                <Table
                  data={orderData}
                  columns={columns}
                  onOrderItemClicked={onOrderItemClicked}
                  isShowActionButton={false}
                />
              </div>
            </div>
          )
        ) : (
          <p className="no-order">{Strings.NO_ORDERS_FOUND}</p>
        )}
        <StaticBackdropModal
          show={show}
          handleOpen={modalData?.onAccept ?? modalData?.onClose ?? handleClose}
          handleClose={handleClose}
          title={modalData?.title}
          children={modalData?.children}
          btnText={modalData?.btnText}
          headerIcon={modalData?.headerIcon}
        />
      </div>
    </>
  );
};
