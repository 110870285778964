import { OrderLine, OrderResponse } from "../apis/types";
import { Strings } from "./strings";

export const defaultCoachAvailabilityWeekRanges = {
  currentWeekStart: new Date(),
  currentWeekEnd: new Date(),
  nextWeekStart: new Date(),
  nextWeekEnd: new Date(),
  afterNextWeekStart: new Date(),
  afterNextWeekEnd: new Date(),
};

export const defaultTimeSlotsData = {
  availableIndoorSlots: {
    bookingSlots: [
      {
        slotName: "07:00-08:30 AM",
        startSlotTime: 25200,
        endSlotTime: 30600,
        isAvailable: true,
      },
      {
        slotName: "08:30-10:00 AM",
        startSlotTime: 30600,
        endSlotTime: 36000,
        isAvailable: true,
      },
      {
        slotName: "10:00-11:30 AM",
        startSlotTime: 36000,
        endSlotTime: 41400,
        isAvailable: true,
      },
      {
        slotName: "11:30-13:00 PM",
        startSlotTime: 41400,
        endSlotTime: 46800,
        isAvailable: true,
      },
      {
        slotName: "13:00-14:30 PM",
        startSlotTime: 46800,
        endSlotTime: 52200,
        isAvailable: true,
      },
      {
        slotName: "14:30-16:00 PM",
        startSlotTime: 52200,
        endSlotTime: 57600,
        isAvailable: true,
      },
      {
        slotName: "16:00-17:30 PM",
        startSlotTime: 57600,
        endSlotTime: 63000,
        isAvailable: true,
      },
      {
        slotName: "17:30-19:00 PM",
        startSlotTime: 63000,
        endSlotTime: 68400,
        isAvailable: true,
      },
      {
        slotName: "19:00-20:30 PM",
        startSlotTime: 68400,
        endSlotTime: 73800,
        isAvailable: true,
      },
      {
        slotName: "20:30-22:00 PM",
        startSlotTime: 73800,
        endSlotTime: 79200,
        isAvailable: true,
      },
      {
        slotName: "22:00-23:30 PM",
        startSlotTime: 79200,
        endSlotTime: 84600,
        isAvailable: true,
      },
    ],
    minimumPlayingTime: 5400,
  },
  availableOutdoorSlots: {
    bookingSlots: [
      {
        slotName: "07:00-08:30 AM",
        startSlotTime: 25200,
        endSlotTime: 30600,
        isAvailable: true,
      },
      {
        slotName: "08:30-10:00 AM",
        startSlotTime: 30600,
        endSlotTime: 36000,
        isAvailable: true,
      },
      {
        slotName: "10:00-11:30 AM",
        startSlotTime: 36000,
        endSlotTime: 41400,
        isAvailable: true,
      },
      {
        slotName: "11:30-13:00 PM",
        startSlotTime: 41400,
        endSlotTime: 46800,
        isAvailable: true,
      },
      {
        slotName: "13:00-14:30 PM",
        startSlotTime: 46800,
        endSlotTime: 52200,
        isAvailable: true,
      },
      {
        slotName: "14:30-16:00 PM",
        startSlotTime: 52200,
        endSlotTime: 57600,
        isAvailable: true,
      },
      {
        slotName: "16:00-17:30 PM",
        startSlotTime: 57600,
        endSlotTime: 63000,
        isAvailable: true,
      },
      {
        slotName: "17:30-19:00 PM",
        startSlotTime: 63000,
        endSlotTime: 68400,
        isAvailable: true,
      },
      {
        slotName: "19:00-20:30 PM",
        startSlotTime: 68400,
        endSlotTime: 73800,
        isAvailable: false,
      },
      {
        slotName: "20:30-22:00 PM",
        startSlotTime: 73800,
        endSlotTime: 79200,
        isAvailable: false,
      },
      {
        slotName: "22:00-23:30 PM",
        startSlotTime: 79200,
        endSlotTime: 84600,
        isAvailable: true,
      },
    ],
    minimumPlayingTime: 5400,
  },
  availableSingleSlots: {
    bookingSlots: [
      {
        slotName: "07:00-07:30 AM",
        startSlotTime: 25200,
        endSlotTime: 27000,
        isAvailable: true,
      },
      {
        slotName: "07:30-08:00 AM",
        startSlotTime: 27000,
        endSlotTime: 28800,
        isAvailable: true,
      },
      {
        slotName: "08:00-08:30 AM",
        startSlotTime: 28800,
        endSlotTime: 30600,
        isAvailable: true,
      },
      {
        slotName: "08:30-09:00 AM",
        startSlotTime: 30600,
        endSlotTime: 32400,
        isAvailable: true,
      },
      {
        slotName: "09:00-09:30 AM",
        startSlotTime: 32400,
        endSlotTime: 34200,
        isAvailable: true,
      },
      {
        slotName: "09:30-10:00 AM",
        startSlotTime: 34200,
        endSlotTime: 36000,
        isAvailable: true,
      },
      {
        slotName: "10:00-10:30 AM",
        startSlotTime: 36000,
        endSlotTime: 37800,
        isAvailable: true,
      },
      {
        slotName: "10:30-11:00 AM",
        startSlotTime: 37800,
        endSlotTime: 39600,
        isAvailable: true,
      },
      {
        slotName: "11:00-11:30 AM",
        startSlotTime: 39600,
        endSlotTime: 41400,
        isAvailable: false,
      },
      {
        slotName: "11:30-12:00 PM",
        startSlotTime: 41400,
        endSlotTime: 43200,
        isAvailable: false,
      },
      {
        slotName: "12:00-12:30 PM",
        startSlotTime: 43200,
        endSlotTime: 45000,
        isAvailable: true,
      },
      {
        slotName: "12:30-13:00 PM",
        startSlotTime: 45000,
        endSlotTime: 46800,
        isAvailable: true,
      },
      {
        slotName: "13:00-13:30 PM",
        startSlotTime: 46800,
        endSlotTime: 48600,
        isAvailable: true,
      },
      {
        slotName: "13:30-14:00 PM",
        startSlotTime: 48600,
        endSlotTime: 50400,
        isAvailable: true,
      },
      {
        slotName: "14:00-14:30 PM",
        startSlotTime: 50400,
        endSlotTime: 52200,
        isAvailable: false,
      },
      {
        slotName: "14:30-15:00 PM",
        startSlotTime: 52200,
        endSlotTime: 54000,
        isAvailable: false,
      },
      {
        slotName: "15:00-15:30 PM",
        startSlotTime: 54000,
        endSlotTime: 55800,
        isAvailable: true,
      },
      {
        slotName: "15:30-16:00 PM",
        startSlotTime: 55800,
        endSlotTime: 57600,
        isAvailable: true,
      },
      {
        slotName: "16:00-16:30 PM",
        startSlotTime: 57600,
        endSlotTime: 59400,
        isAvailable: false,
      },
      {
        slotName: "16:30-17:00 PM",
        startSlotTime: 59400,
        endSlotTime: 61200,
        isAvailable: false,
      },
      {
        slotName: "17:00-17:30 PM",
        startSlotTime: 61200,
        endSlotTime: 63000,
        isAvailable: false,
      },
      {
        slotName: "17:30-18:00 PM",
        startSlotTime: 63000,
        endSlotTime: 64800,
        isAvailable: true,
      },
      {
        slotName: "18:00-18:30 PM",
        startSlotTime: 64800,
        endSlotTime: 66600,
        isAvailable: true,
      },
      {
        slotName: "18:30-19:00 PM",
        startSlotTime: 66600,
        endSlotTime: 68400,
        isAvailable: true,
      },
      {
        slotName: "19:00-19:30 PM",
        startSlotTime: 68400,
        endSlotTime: 70200,
        isAvailable: true,
      },
      {
        slotName: "19:30-20:00 PM",
        startSlotTime: 70200,
        endSlotTime: 72000,
        isAvailable: true,
      },
      {
        slotName: "20:00-20:30 PM",
        startSlotTime: 72000,
        endSlotTime: 73800,
        isAvailable: true,
      },
      {
        slotName: "20:30-21:00 PM",
        startSlotTime: 73800,
        endSlotTime: 75600,
        isAvailable: true,
      },
      {
        slotName: "21:00-21:30 PM",
        startSlotTime: 75600,
        endSlotTime: 77400,
        isAvailable: true,
      },
      {
        slotName: "21:30-22:00 PM",
        startSlotTime: 77400,
        endSlotTime: 79200,
        isAvailable: true,
      },
      {
        slotName: "22:00-22:30 PM",
        startSlotTime: 79200,
        endSlotTime: 81000,
        isAvailable: true,
      },
      {
        slotName: "22:30-23:00 PM",
        startSlotTime: 81000,
        endSlotTime: 82800,
        isAvailable: false,
      },
      {
        slotName: "23:00-23:30 PM",
        startSlotTime: 82800,
        endSlotTime: 84600,
        isAvailable: false,
      },
    ],
    minimumPlayingTime: 1800,
  },
};

export const dummyDatesString = [
  "2024-05-21",
  "2024-05-22",
  "2024-04-23",
  "2023-05-25",
  "2023-05-24",
  "2024-05-28",
  "2024-06-02",
  "2024-06-04",
  "2023-06-25",
  "2023-06-15",
];

const orderLine1: OrderLine = {
  id: 1,
  productId: 101,
  productName: "Product A",
  quantity: 2,
  status: "Pending",
  staffDeliveryDate: "2024-06-19T10:00:00Z",
  createdDate: "2024-06-18T14:30:00Z",
};

const orderLine2: OrderLine = {
  id: 2,
  productId: 102,
  productName: "Product B",
  quantity: 1,
  status: "Delivered",
  staffDeliveryDate: "2024-06-20T09:00:00Z",
  createdDate: "2024-06-18T15:45:00Z",
};

// Dummy data for OrderResponse
const orderResponse1: OrderResponse = {
  id: 1001,
  partnerId: 201,
  name: "Order 1",
  courtName: "Court 1",
  amountTotal: 150.75,
  orderLines: [orderLine1, orderLine2],
};

const orderResponse2: OrderResponse = {
  id: 1002,
  partnerId: 202,
  name: "Order 2",
  courtName: "Court 2",
  amountTotal: 89.99,
  orderLines: [orderLine2],
};

// Exporting dummy data
export const dummyOrders: OrderResponse[] = [orderResponse1, orderResponse2];

export const coachDefaultCoachAvailabilityWeekRanges = {
  currentWeekStart: new Date(),
  currentWeekEnd: new Date(),
  nextWeekStart: new Date(),
  nextWeekEnd: new Date(),
  afterNextWeekStart: new Date(),
  afterNextWeekEnd: new Date(),
};

export const coachDefaultTimeSlotsData = [
  { id: 1, name: "07:00 AM", state: Strings.DEFAULT },
  { id: 2, name: "08:00 AM", state: Strings.DEFAULT },
  { id: 3, name: "09:00 AM", state: Strings.DEFAULT },
  { id: 4, name: "10:00 AM", state: Strings.DEFAULT },
  { id: 5, name: "11:00 AM", state: Strings.DEFAULT },
  { id: 6, name: "12:00 PM", state: Strings.DEFAULT },
  { id: 7, name: "01:00 PM", state: Strings.DEFAULT },
  { id: 8, name: "02:00 PM", state: Strings.DEFAULT },
  { id: 9, name: "03:00 PM", state: Strings.DEFAULT },
  { id: 10, name: "04:00 PM", state: Strings.DEFAULT },
  { id: 11, name: "05:00 PM", state: Strings.DEFAULT },
  { id: 12, name: "06:00 PM", state: Strings.DEFAULT },
  { id: 13, name: "07:00 PM", state: Strings.DEFAULT },
  { id: 14, name: "08:00 PM", state: Strings.DEFAULT },
  { id: 15, name: "09:00 PM", state: Strings.DEFAULT },
];
