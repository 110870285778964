import React from "react";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "jotai";
import { FontDeskProvider } from "./context/frontDeskContext";
import RootRouter from "./routes";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <Provider>
      <Router>
        <FontDeskProvider>
          <RootRouter />

          <ToastContainer />
        </FontDeskProvider>
      </Router>
    </Provider>
  );
}

export default App;
