import React, { ReactNode, useEffect, useState } from "react";
import { ACCESS_TOKEN, FCM_TOKEN } from "../constants";
import { useNavigate } from "react-router-dom";
import { isIOS } from "react-device-detect";
import { UserModel } from "../apis/types";
import { FrontDeskApi } from "../apis/frontDesk.api";
import { deleteFCMToken } from "../firebase";
import { CoachAvailabilityWeekRanges } from "../types/types";
import { coachDefaultCoachAvailabilityWeekRanges } from "../utils/data";
import {
  getAfterNextWeekRange,
  getEndOfWeek,
  getNextWeekRange,
} from "../utils/utils";

type FrontDeskContextType = {
  isAuthenticated?: boolean | null;
  onLogOut: () => void;
  fetchMe: (callback?: any) => void;
  setIosInstructions: React.Dispatch<React.SetStateAction<boolean>>;
  setNotificationPermission: React.Dispatch<
    React.SetStateAction<NotificationPermission | undefined>
  >;
  iosInstructions: boolean;
  notificationPermission: NotificationPermission | undefined;
  isShowIosInstructions: () => void;
  getFCMToken: () => string;
  staffModel: UserModel | undefined;
  setFCMToken: (value: string) => void;
  onBookPro: () => void;
  coachAvailabilityWeekRanges: CoachAvailabilityWeekRanges;
  onCoachAvailability: () => void;
  onOrders: () => void;
};

const FontDeskContext = React.createContext<FrontDeskContextType>({
  onLogOut: () => {},
  fetchMe: () => {},
  setIosInstructions: () => {},
  setNotificationPermission: () => {},
  iosInstructions: false,
  notificationPermission: undefined,
  isShowIosInstructions: () => {},
  getFCMToken: () => "",
  staffModel: undefined,
  setFCMToken: (value: string) => {},
  onBookPro: () => {},
  coachAvailabilityWeekRanges: coachDefaultCoachAvailabilityWeekRanges,
  onCoachAvailability: () => {},
  onOrders: () => {},
});

type FontDeskProviderProps = {
  children: ReactNode | ReactNode[];
};

function FontDeskProvider(props: FontDeskProviderProps) {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [iosInstructions, setIosInstructions] = useState(false);
  const [notificationPermission, setNotificationPermission] =
    useState<NotificationPermission>();
  const [staffModel, setStaffModel] = useState<UserModel>();
  const [coachAvailabilityWeekRanges, setCoachAvailabilityWeekRanges] =
    useState<CoachAvailabilityWeekRanges>(
      coachDefaultCoachAvailabilityWeekRanges
    );
  const isShowIosInstructions = () => {
    if (!("Notification" in window)) {
      if (isIOS) {
        setIosInstructions(true);
      }
    } else {
      setNotificationPermission(Notification.permission);
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    fetchMe();
    const today = new Date();
    const currentWeekStart = today;
    const currentWeekEnd = getEndOfWeek(today);
    const { start: nextWeekStart, end: nextWeekEnd } = getNextWeekRange(today);
    const { start: afterNextWeekStart, end: afterNextWeekEnd } =
      getAfterNextWeekRange(today);
    setCoachAvailabilityWeekRanges({
      currentWeekStart,
      currentWeekEnd,
      nextWeekStart,
      nextWeekEnd,
      afterNextWeekStart,
      afterNextWeekEnd,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLogOut = () => {
    navigate("/otp-login");
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(FCM_TOKEN);
    setStaffModel(undefined);
    setIsAuthenticated(false);
    deleteFCMToken();
  };

  const onBookPro = () => {
    navigate("/book-pro");
  };
  const onCoachAvailability = () => {
    navigate("/coach-availability");
  };
  const onOrders = () => {
    navigate("/orders");
  };

  const fetchMe = async (callback?: () => null) => {
    const token = localStorage.getItem(ACCESS_TOKEN);
    isShowIosInstructions();

    if (token) {
      const user = FrontDeskApi.getMe();
      user.then((response) => {
        setStaffModel(response);
      });
    }

    setIsAuthenticated(!!token);
  };

  const getFCMToken = () => {
    return localStorage.getItem(FCM_TOKEN) ?? "";
  };

  const setFCMToken = (token: string) => {
    localStorage.setItem(FCM_TOKEN, token);
  };

  return (
    <FontDeskContext.Provider
      value={{
        onLogOut,
        isAuthenticated,
        fetchMe,
        setIosInstructions,
        setNotificationPermission,
        iosInstructions,
        notificationPermission,
        isShowIosInstructions,
        getFCMToken,
        staffModel,
        setFCMToken,
        onBookPro,
        coachAvailabilityWeekRanges,
        onCoachAvailability,
        onOrders,
      }}
      {...props}
    />
  );
}

function useFontDesk() {
  const context = React.useContext(FontDeskContext);

  if (context === undefined) {
    throw new Error("useCoach must be used within a CoachProvider");
  }
  return context;
}

export { FontDeskProvider, useFontDesk };
