import React from "react";
import { Navigate } from "react-router-dom";
import { useFontDesk } from "../context/frontDeskContext";

const PrivateRoute = ({ children }: any) => {
  const { isAuthenticated } = useFontDesk();

  if (isAuthenticated === null) return;

  if (!isAuthenticated) {
    return <Navigate to="/otp-login" />;
  }

  return children;
};

export default PrivateRoute;
